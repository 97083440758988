<template>
  <div>
    <Modal :options="{width: '30vw',type: 'danger'}" @close="$emit('close')">
      <div class="title">Resolver incidencia</div>
      <div class="body">
        <p class="text-center">Al resolver la incidencia no se podrá volver a editar <br/> ¿Estas seguro de resolver continuar?</p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-success mr-2" @click="resolver_incidencia">Resolver</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
      ,incidencia: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
    }
    ,methods: {
      async resolver_incidencia() {
        try {
          let payload = {
            incidencia_id: this.incidencia.id
            ,resolucion: true
          }

          let res = (await apiSolicitud.incidencias_editar(this.solicitud.id, payload)).data
          this.$log.info('res',res);

          this.$emit('update')
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>